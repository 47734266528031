import { ThemeProvider } from '@emotion/react';
import { GETProfileAPI } from 'api/v2/profile';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Menu from 'shared/components/menu';
import { envResolver } from 'shared/configs/env-resolver';
import getDesignToken from 'shared/configs/theme';
import { useAppSelector } from 'shared/hooks/redux-helper';
import UseEnergySync from 'shared/hooks/use-energy-sync';
import UseTapSync from 'shared/hooks/use-tap-sync';
import requestHandler from 'shared/utils/request-handler';
import { changePrana, changeProfileLogin, changeProfileOverview } from 'store/reducers/profile';
import QrCode from '../qr-code';
import Splash from '../splash';
import Walkthrough from '../walkthrough/walkthrough';
import { LayoutContainer } from './layout.style';
import Telegram from 'shared/utils/telegram';

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
	const isLogin = useAppSelector(state => state.profile.isLogin);
	const walkthrough = useAppSelector(state => state.profile.walkthrough);

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isMobile, setIsMobile] = useState<boolean | null>(null);
	const [localUserId, setUserId] = useState<number>(0);

	useEffect(() => {
		if (isLogin) {
			dispatch(changeProfileLogin({ isLogin: false, avatar: '', name: '' }));
		}
		const { userId } = Telegram().getTelegramData();
		setUserId(+userId);

		if (
			+userId !== 5365969509 && //reza
			+userId !== 546699062 && //SaeedArashfar
			+userId !== 6778585565 && //saeed_developer
			envResolver.MODE !== 'STAGE' &&
			window.Telegram.WebApp.platform !== 'ios' &&
			window.Telegram.WebApp.platform !== 'android'
		) {
			setIsMobile(false);
		} else {
			setIsMobile(true);
			requestHandler.loginUser().then(res => {
				if (!window.Telegram?.WebApp?.isExpanded) {
					window.Telegram.WebApp.expand();
					document.body.style.height = 'calc(100% + 2px)';
				}
				document.body.style.height = 'calc(100% + 2px)';

				window.Telegram.WebApp.BackButton.isVisible = true;

				window.Telegram.WebApp.BackButton.onClick(() => {
					navigate('/');
				});

				if (res) {
					const { photo_url, username } = res;
					dispatch(changeProfileLogin({ isLogin: true, avatar: photo_url, name: username }));

					UseEnergySync(dispatch);
					UseTapSync(dispatch);

					GETProfileAPI().then(res => {
						dispatch(changeProfileOverview(res));
						dispatch(changePrana(res.prana));
						setIsLoading(false);
					});
				}
			});
		}
	}, []);

	return (
		<ThemeProvider theme={getDesignToken()}>
			{(isMobile || localUserId === 5365969509) && (
				<LayoutContainer>
					{isLoading ? (
						<Splash />
					) : !walkthrough ? (
						<Walkthrough />
					) : (
						<>
							<Menu />
							{children}
						</>
					)}
				</LayoutContainer>
			)}

			{isMobile === false && localUserId !== 5365969509 && <QrCode />}

			{/* <div className='bottom_right_light'></div> */}
		</ThemeProvider>
	);
};

export default Layout;
